@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
:root {
  --primary-color: #091a06;
  --secondary-color: #88866e;
  --accent-color: #876d1a;
  --text-color: #88866e;
  --subtitle-color: #222b22;
  --font-one: 'Bebas Neue', sans-serif;
  --font-p: 'Sofia Sans', sans-serif;
}

p {
  color: var(--text-color) !important;
  font-weight: 700;
  line-height: 1.3;
  font-size: 1.2rem;
  font-family: var(--font-p);
}
a {
  font-family: var(--font-one);
  letter-spacing: 1px;
  font-size: 1.5rem;
  color: var(--secondary-color);
}

h1 {
  font-family: var(--font-one);
  color: var(--primary-color);
  font-size: 2rem;
  letter-spacing: 10px;
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }
}

h2 {
  margin: 0px;
  padding-bottom: 0px;
  color: var(--secondary-color);
  font-family: var(--font-one);
  letter-spacing: 2px;
  font-size: 1.5rem;
}

h3 {
  color: var(--subtitle-color);
  font-family: var(--font-one);
  letter-spacing: 1px;
  font-size: 1.3rem;
}

ul {
  list-style-type: square;
}

li {
  color: var(--text-color);
  font-weight: 700;
  margin-bottom: 20px;
}

strong {
  color: var(--primary-color);
}
