@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Sofia+Sans:wght@100;200;300;400;500;600;700;800;900;1000&display=swap');

.pageBody {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 40px;
  border: 2px solid var(--primary-color);
  align-items: center;
  box-shadow: 0 0 500px var(--secondary-color);
}

@media (max-width: 992px) {
  .pageBody {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border: none;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6vw;
  padding-top: 0;
  max-width: 1100px;
}

@media (max-width: 480px) {
  .contentContainer {
    margin: 0;
    padding: 0.3rem;
    max-width: 100%;
    padding-top:0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .contentContainer {
    margin: 0;
    padding: 1.5rem;
    max-width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .contentContainer {
    margin: 0;
    padding: 3rem;
    max-width: 100%;
  }
}

section {
  margin-bottom: 5rem;
}

@media screen and (max-width: 768px) {
  section {
    margin-bottom: 2rem;
  }
}
