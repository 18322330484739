.company-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.experienceContainer {
  column-count: 2;
  column-gap: 1rem;
}

.experienceContainer > div {
  break-inside: avoid; /* Prevents the item from being split across columns */
}

@media (max-width: 680px) {
  .experienceContainer {
    display: flex;
    flex-direction: column;
  }
}

.bubble {
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  background: linear-gradient(to right, #091a0619, #88866e00);
}
