.screenshotsContainer {
  position: relative;
  height: 70vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  background-color: black;
  padding: 1rem;
  padding-bottom: 0;
}

@media screen and (max-width: 768px) {
  .screenshotsContainer {
    height: 100%;
    min-height: 60vh;
    padding: 0;
  }
}

.slideshow {
  position: relative;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  justify-content: space-between;
  align-items: center;
}

.slideshow img {
  object-fit: contain;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: black;
  gap: 1rem;
}
.slideshowNextButton {
  width: 100%;
  right: 0;
  height: 5%;
  background-color: black;
  color: white;
  cursor: pointer;
  opacity: 0.8;
  font-size: 2rem;
  z-index: 2;
  border: none;
}

.slideshowPrevButton {
  width: 100%;
  height: 5%;
  left: 0;
  background-color: black;
  opacity: 0.8;
  color: white;
  cursor: pointer;
  font-size: 2rem;
  z-index: 2;
  border: none;
}

.slideshowPrevButton:hover {
  background-color: var(--secondary-color);
}

.slideshowNextButton:hover {
  background-color: var(--secondary-color);
}

.slideshowNextButton {
  font-size: 2rem;
}

.slideshowNextButton h1 {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  color: white;
}

.slideshowPrevButton h1 {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  color: white;
}
