.navbar {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;
  top: 0;
  z-index: 3;
  min-width: 100%;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  margin-bottom: 100px;
}

.mobile-nav {
  display: flex;
  flex-direction: row;
  z-index: 1;
  transition: 0.2s;
}

@media screen and (max-width: 768px) {
  .navbar {
    margin-bottom: 0;
  }
  .mobile-nav {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

/* The navigation menu links */
.mobile-nav a {
  text-decoration: none;
  transition: 0.3s;
}

.mobile-nav h3 {
  display: block;
  padding: 1rem;
  font-weight: 100;
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  letter-spacing: 1vw; /* Adjust the value as needed */
  color: white;
  margin-left: 1rem;
  padding-bottom: 0;
  padding-top: 0;
  margin: 0;
}

/* When you mouse over the navigation links, change their color */
.mobile-nav h3:hover {
  color: var(--secondary-color);
  cursor: pointer;
}
