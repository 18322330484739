.aboutContent {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}

@media (max-width: 768px) {
  .linksContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}

.link {
  margin-top: 1rem;
  margin-bottom: 1rem;

  text-decoration: none;
  color: var(--accent-color);
  font-weight: 600;
}

.link:hover {
  text-decoration: underline;
}

.miniProjectsSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  justify-items: space-between;
  align-items: center;
  margin-top: 4rem;
}

.infoParas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

@media (max-width: 768px) {
  .infoParas {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
}

.bubble {
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background: linear-gradient(to right, #091a0619, #88866e00);
}

.bubble h2 {
  padding: 3rem;
}

@media (max-width: 768px) {
  .bubble {
    margin-bottom: 0.5rem;
    padding: 0px;
  }
  .bubble h2 {
    padding: 0.5rem;
  }
}
