.bubble {
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 2rem;
  background: linear-gradient(to right, #091a0619, #88866e00);
}

.descriptionContainer {
  column-count: 2;
  column-gap: 1rem;
}

@media screen and (max-width: 768px) {
  .descriptionContainer {
    column-count: 1;
  }
}

.descriptionContainer > div {
  break-inside: avoid; /* Prevents the item from being split across columns */
}

.titleIcons {
  display: flex;
  justify-content: space-between;
}

.icons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

@media (max-width: 768px) {
  .titleIcons {
    flex-direction: column;
    justify-content: flex-start;
  }
  .icons {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
  }
}
